
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "billingInvoicesSelf": {
      "id": "billing_invoices.self",
      "defaultMessage": "Invoices"
    },
    "billingInvoicesDisputing": {
      "id": "billing_invoices.disputing",
      "defaultMessage": "in dispute"
    },
    "billingInvoicesDisputed": {
      "id": "billing_invoices.disputed",
      "defaultMessage": "disputed"
    },
    "billingInvoicesEmpty": {
      "id": "billing_invoices.empty",
      "defaultMessage": "No invoices."
    },
    "billingInvoicesFailed": {
      "id": "billing_invoices.failed",
      "defaultMessage": "overdue"
    },
    "billingInvoicesNotPaid": {
      "id": "billing_invoices.not_paid",
      "defaultMessage": "not paid"
    },
    "billingInvoicesOverdue": {
      "id": "billing_invoices.overdue",
      "defaultMessage": "overdue"
    },
    "billingInvoicesPaid": {
      "id": "billing_invoices.paid",
      "defaultMessage": "paid"
    },
    "billingInvoicesPastDue": {
      "id": "billing_invoices.past_due",
      "defaultMessage": "past due"
    },
    "billingInvoicesPaymentDue": {
      "id": "billing_invoices.payment_due",
      "defaultMessage": "payment due"
    },
    "billingInvoicesPending": {
      "id": "billing_invoices.pending",
      "defaultMessage": "pending"
    },
    "billingInvoicesRefunded": {
      "id": "billing_invoices.refunded",
      "defaultMessage": "refunded"
    },
    "billingInvoicesUpcoming": {
      "id": "billing_invoices.upcoming",
      "defaultMessage": "upcoming"
    },
    "billingInvoicesVoided": {
      "id": "billing_invoices.voided",
      "defaultMessage": "voided"
    },
    "billingInvoicesShow": {
      "id": "billing_invoices.show",
      "defaultMessage": "View Invoice"
    },
    "billingInvoicesSubscription": {
      "id": "billing_invoices.subscription",
      "defaultMessage": "Subscription"
    },
    "billingInvoicesTaxes": {
      "id": "billing_invoices.taxes",
      "defaultMessage": "plus sales tax where applicable"
    },
    "billingInvoicesUncollectible": {
      "id": "billing_invoices.uncollectible",
      "defaultMessage": "uncollectible"
    },
    "billingInvoicesVoid": {
      "id": "billing_invoices.void",
      "defaultMessage": "voided"
    }
  }
);
export default defaultMessages;
